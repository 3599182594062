import React from 'react';

import Pricing from '../modules/pages/pricing/Pricing';

const pricing = () => {
  return (
    <Pricing
      seo={{
        title: 'Pricing',
        description:
          'Understanding your customer has never been so easy! Integrate today and create your analysis in a few minutes.',
      }}
    />
  );
};

export default pricing;
